.review-container {
  max-width: 400px;
  margin: 0 auto;
}

.review-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}

input[type='number'],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button1[type='submit'] {
  padding: 8px 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button1[type='submit']:hover {
  background-color: #45a049;
}


.procedure-reviews-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 20px;
}

h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

p {
  margin: 0;
}

.rating {
  display: inline-block;
  color: #ffd700; /* Gold color for the star */
}

.comment {
  margin-top: 5px;
}

.date-created {
  font-style: italic;
  color: #999;
}



