/* latest-news */
 .news-tag {
     color: #e12454;
     font-weight: 500;
}
 .latest-news-content h3 {
     font-size: 22px;
     line-height: 1.3;
     margin-bottom: 19px;
}
 .latest-news-content h3 a:hover {
     color: #e12454;
}
 .recent-news-list {
     padding: 41px;
     border: 2px solid #eae7ff;
     padding-bottom: 40px;
}
 .singl-news.news-border-bottom {
     padding-bottom: 31px;
     border-bottom: 2px solid #e3ecf2;
     margin-bottom: 31px;
}
 .meta-date {
     margin-right: 29px;
}
 .meta-date i {
     margin-right: 5px;
}
 .meta-date a {
     color: #647589;
}
 .latest-news-box-2 .latest-news-content-box {
     padding: 0 15px;
}
 .latest-news-box-2 .latest-news-content {
     padding: 40px;
     background: #fff;
     margin-top: -30px;
     position: relative;
     z-index: 9;
     padding-bottom: 15px;
}
 .latest-news-box-2 .latest-news-content h3 {
     margin-bottom: 13px;
}
 .latest-news-box-2 .news-tag {
     color: #8fb569;
}
 .latest-news-box-2 .latest-news-content h3 a:hover {
     color: #8fb569;
}
 .blog-feeds-text h5 {
     color: #bdc1d5;
     line-height: 1.3;
     margin-bottom: 4px;
}
 .latest-news-box-3 {
     background-color: rgb(255, 255, 255);
     box-shadow: 0px 8px 16px 0px rgba(178, 176, 255, 0.2);
}
 .latest-news-box-3 .latest-news-content {
     margin-top: 0;
}

/* 19. Blog */
 .latest-news {
     position: relative;
}
 .news__thumb img {
     width: 100%;
}
 .postbox__thumb img {
     width: 100%;
}
 .blog-inner-img img{
     width: 100%;
}
 .news-meta span {
     font-weight: 500;
     letter-spacing: 2px;
     text-transform: uppercase;
     color: #747691;
     margin-right: 15px;
     font-size: 13px;
}
 .news-meta span a {
     color: #747691;
}
 .news-meta span a:hover {
     color: #e12454;
}
 .news__caption h2 {
     font-size: 24px;
     margin-bottom: 15px;
     line-height: 1.4;
}
 .news__caption h2 a:hover {
     color: #e12454;
}
 .news-link {
     font-size: 12px;
     color: #e4ecf3;
     font-weight: 500;
     text-transform: uppercase;
     letter-spacing: 2px;
}
 .news-link:hover {
     color: #ffffff;
}
 .news-link i {
     transition: .3s;
     display: inline-block;
}
 .news-link:hover i {
     transform: translateX(3px);
}
 .news__thumb-2 img {
     width: 100%;
}
 .news__caption-2 {
     padding: 33px;
}
 .news__caption-2 h2 {
     font-size: 20px;
     margin-bottom: 15px;
}
 .news__caption-2 h2 a:hover {
     color: #e12454;
}
 .news__caption-2 p {
     margin-bottom: 0;
}
 .news-meta span {
     text-transform: uppercase;
     margin-right: 25px;
     font-size: 13px;
}
 @media only screen and (min-width: 992px) and (max-width: 1200px) {
     .news-meta span {
         margin-right: 10px;
    }
}
 .news-meta span a {
     color: #e12454;
     letter-spacing: 2px;
     font-weight: 500;
}
 .postbox__thumb {
     position: relative;
}
 .postbox__thumb a img {
     max-width: 100%;
}
 .post-meta span {
     font-size: 12px;
     letter-spacing: 2px;
     font-weight: 500;
     text-transform: uppercase;
     color: #6f7172;
     margin-right: 20px;
}
 @media (max-width: 767px) {
     .post-meta span {
         margin-right: 10px;
    }
}
 .post-meta span a:hover {
     color: #e12454;
}
 .post-meta span i {
     color: #e12454;
     margin-right: 2px;
}
 .post-meta span a {
     color: #6f7172;
}
 .postbox__text {
     background: #faf9ff;
}
 .p-50 {
     padding: 50px;
}
 @media only screen and (min-width: 992px) and (max-width: 1200px) {
     .p-50 {
         padding: 30px;
    }
}
 @media (max-width: 767px) {
     .p-50 {
         padding: 30px;
    }
}
 .p-30 {
     padding: 30px;
}
 .post-meta {
     margin-bottom: 20px;
}
 .blog-title {
     font-size: 28px;
     line-height: 1.3;
     margin-bottom: 20px;
}
 .blog-title a:hover {
     color: #e12454;
}
 .read-more {
     letter-spacing: 2px;
     font-size: 12px;
     text-transform: uppercase;
     font-weight: 500;
}
 .read-more{
     color: #e12454;
}
 .read-more:hover {
     color: #8fb569;
}
 .read-more i {
     transition: .3s;
     display: inline-block;
}
 .blog-title-sm {
     font-size: 20px;
     margin-bottom: 10px;
     line-height: 1.5;
}
 .postbox__gallery .slick-arrow {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     left: 30px;
     border: none;
     background: none;
     padding: 0;
     z-index: 9;
     -webkit-transition: all 0.3s ease-out 0s;
     -moz-transition: all 0.3s ease-out 0s;
     -ms-transition: all 0.3s ease-out 0s;
     -o-transition: all 0.3s ease-out 0s;
     transition: all 0.3s ease-out 0s;
     background: #ffffff;
     width: 60px;
     height: 60px;
     color: #6f7172;
     border-radius: 50%;
     font-size: 14px;
}
 .postbox__gallery .slick-arrow:hover {
     background: #e12454;
     color: #ffffff;
}
 .postbox__gallery .slick-arrow.slick-next {
     right: 30px;
     left: auto;
}
 .postbox__video {
     position: relative;
}
 .video-btn {
     position: absolute;
     background: #ffffff;
     height: 120px;
     width: 120px;
     top: 50%;
     left: 0;
     right: 0;
     margin: auto;
     text-align: center;
     border-radius: 50%;
     line-height: 120px;
     color: #e12454;
     transform: translateY(-50%);
     border: none;
}
 .video-btn:hover {
     background: #e12454;
     color: #ffffff;
}
 .post-text blockquote {
     background: #062a4d;
     padding: 50px;
     position: relative;
     padding-left: 80px;
}
 @media (max-width: 767px) {
     .post-text blockquote {
         padding: 30px;
    }
}
 .post-text blockquote p {
     font-size: 26px;
     margin-bottom: 20px;
     color: #ffffff;
     font-weight: 500;
     line-height: 1.3;
}


 .post-text blockquote footer {
     font-size: 14px;
     letter-spacing: 2px;
     color: #ffffff;
     font-weight: 500;
     text-transform: uppercase;
}
 .widget {
     padding: 30px;
     background-color: rgb(255, 255, 255);
     box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);
     border-top: 4px solid #8fb569;
}
 .b-0 {
     border: 0;
}
 .search-form {
     position: relative;
}
 .search-form input {
     width: 100%;
     height: 60px;
     border: 0;
     background: #f5f5f5;
     padding: 0 20px;
}
 .search-form button {
     position: absolute;
     top: 0;
     right: 0;
     height: 100%;
     background: #062a4d;
     padding: 0 25px;
     color: #ffffff;
     line-height: 60px;
     border: 0;
}
 .search-form button:hover {
     background: #e12454;
}
 .widget-title {
     font-size: 21px;
     margin-bottom: 0px;
     position: relative;
     display: inline-block;
}
 .animate-border::after {
     position: absolute;
     content: "";
     width: 35px;
     height: 3px;
     left: 0;
     bottom: 0;
     border-left: 10px solid #ffffff;
     border-right: 10px solid #ffffff;
     -webkit-animation: animborder 2s linear infinite;
     animation: animborder 2s linear infinite;
}
 .animate-border.border-white::after {
     border-color: #ffffff;
}
 .animate-border.border-white::after {
     border-color: #ffffff;
}
 .about-me img {
     margin-bottom: 25px;
     border-radius: 50%;
}
 .about-me h4 {
     font-size: 18px;
     letter-spacing: 1px;
}
 .widget-social-icon a {
     display: inline-block;
     margin: 0 8px;
     color: #8fb569;
}
 .widget-social-icon a:hover {
     color: #e12454;
}
 .banner-widget img {
     max-width: 100%;
}
 .widget .recent-posts > li:first-child {
     border: 0;
     padding-top: 0;
}
 .widget .recent-posts li:last-child {
     padding-bottom: 0;
}
 .widget .recent-posts > li {
     border-top: 1px solid #eaedff;
     padding: 20px 0;
     overflow: hidden;
}
 .widget-posts-image {
     float: left;
}
 .widget-posts-image img {
     width: 80px;
     height: 80px;
     border-radius: 50%;
     margin-right: 15px;
}
 .widget-posts-body {
     overflow: hidden;
}
 .widget-posts-title {
     margin-bottom: 10px;
     font-size: 16px;
     line-height: 1.3;
}
 .widget-posts-title a:hover {
     color: #e12454;
}
 .widget-posts-meta {
     font-size: 12px;
}
 ul.cat li {
     border-top: 1px solid #eaedff;
     padding: 15px 0;
     overflow: hidden;
}
 ul.cat li:first-child {
     border-top: 0;
     padding-top: 0;
}
 ul.cat li:last-child {
     padding-bottom: 0;
}
 ul.cat li a {
     display: block;
     color: #647589;
}
 ul.cat li a:hover {
     color: #e12454;
}
 .social-profile a {
     height: 50px;
     width: 50px;
     text-align: center;
     background: #062a4d;
     line-height: 50px;
     margin-right: 2px;
     display: inline-block;
     color: #ffffff;
     margin-bottom: 5px;
}
 .social-profile a:hover {
     background: #e12454;
}
 #Instafeed {
     margin: 0 -5px;
     overflow: hidden;
}
 #Instafeed li {
     width: 33.33%;
     float: left;
     padding: 0 5px;
     margin-bottom: 10px;
}
 #Instafeed li img {
     width: 100%;
}
 .tag a {
     display: inline-block;
     line-height: 1;
     padding: 12px 18px;
     background: #f5f5f5;
     margin-bottom: 8px;
     margin-right: 5px;
     text-transform: uppercase;
     font-size: 12px;
     font-weight: 500;
     color: #5f5b5b;
     border-radius: 2px;
}
 .tag a:hover {
     background: #e12454;
     color: #ffffff;
}
 .bg-none {
     background: none;
}
 .blog-post-tag span {
     font-size: 18px;
     font-weight: 600;
     display: block;
     margin-bottom: 17px;
     font-family: "Roboto", sans-serif;
     color: #062a4d;
}
 .blog-post-tag a {
     color: #646974;
     display: inline-block;
     font-size: 14px;
     font-weight: 500;
     text-transform: uppercase;
     border: 2px solid #eaedff;
     padding: 12px 24px;
     margin-right: 10px;
     transition: .3s;
     border-radius: 5px;
     margin-bottom: 10px;
}
 .blog-post-tag a:hover {
     color: #fff;
     background: #e12454;
     border-color: #e12454;
}
 .blog-post-tag a:last-child {
     margin-right: 0;
}
 .blog-share-icon span {
     font-size: 18px;
     font-weight: 600;
     font-family: "Roboto", sans-serif;
     color: #062a4d;
     display: block;
     margin-bottom: 17px;
}
 .blog-share-icon a {
     color: #6f7172;
     font-size: 18px;
     transition: .3s;
     margin-right: 22px;
}
 .blog-share-icon a:hover {
     color: #e12454;
}
 .blog-share-icon a:last-child {
     margin-right: 0;
}
 .blog-details-border {
     border-top: 1px solid #eaedff;
     border-bottom: 1px solid #eaedff;
     padding-top: 33px;
     padding-bottom: 23px;
     margin-bottom: 42px;
}
 .author {
     background: #f5f5f5;
     padding: 0 60px 44px 60px;
}
 @media (max-width: 767px) {
     .author {
         padding: 0 30px 44px 30px;
    }
}
 .author-img {
     margin-bottom: 35px;
}
 .author-img img {
     margin-top: -60px;
}
 .author-text h3 {
     font-size: 24px;
     margin-bottom: 15px;
}
 .author-text p {
     margin-bottom: 0;
     font-size: 15px;
     line-height: 28px;
}
 .author-icon {
     margin-top: 6px;
     margin-bottom: 25px;
}
 .author-icon a {
     font-size: 14px;
     color: #9498b6;
     margin: 0 5px;
     transition: .3s;
}
 .author-icon a:hover {
     color: #e12454;
}
 .post-comments {
     border-bottom: 1px solid #eaedff;
     margin-bottom: 42px;
     padding-bottom: 46px;
}
 .blog-coment-title h2 {
     font-size: 26px;
     margin-bottom: 0;
}
 .comments-avatar {
     float: left;
}
 @media (max-width: 767px) {
     .comments-avatar {
         float: none;
    }
}
 .comments-avatar img {
     width: 100px;
     margin-right: 20px;
}
 .latest-comments li:first-child .comments-box {
     border-top: 0;
     padding-top: 0;
}
 .latest-comments li:last-child .comments-box {
     border-bottom: 0;
     padding-bottom: 0;
}
 .latest-comments li.children {
     margin-left: 100px;
}
 @media (max-width: 767px) {
     .latest-comments li.children {
         margin-left: 0;
    }
}
 .comments-box {
     border-top: 1px solid #eaedff;
     padding: 30px 0;
}
 .comments-text {
     overflow: hidden;
     padding-top: 4px;
}
 @media (max-width: 767px) {
     .comments-text {
         margin-top: 15px;
    }
}
 .comments-text p {
     margin-bottom: 0;
     margin-top: 8px;
}
 .avatar-name h5 {
     font-size: 18px;
     margin-bottom: 7px;
}
 .avatar-name span {
     color: #e12454;
     font-size: 12px;
     font-weight: 500;
     text-transform: uppercase;
     letter-spacing: 2px;
}
 .avatar-name {
     margin-bottom: 3px;
     overflow: hidden;
     position: relative;
}
 .reply {
     color: #6f7172;
     display: inline-block;
     font-size: 14px;
     font-weight: 400;
     transition: .3s;
     line-height: 1;
     margin-top: 4px;
     position: absolute;
     right: 0;
     top: 0;
}
 .reply:hover {
     color: #e12454;
}
 .reply i {
     margin-right: 5px;
}
 .post-comments-title {
     margin-bottom: 39px;
}
 .post-comments-title h2 {
     font-size: 26px;
     color: #140a20;
     margin-bottom: 0;
}
 .conatct-post-form {
     background: #f5f5f5;
     padding: 50px;
}
 @media (max-width: 767px) {
     .conatct-post-form {
         padding: 20px;
    }
}
 .conatct-post-form input {
     border: none;
     height: 60px;
     margin-bottom: 20px;
     padding: 0 30px;
     width: 100%;
     font-size: 14px;
     color: #a7a7c1;
}
 .conatct-post-form textarea {
     border: none;
     height: 150px;
     margin-bottom: 20px;
     padding: 30px;
     width: 100%;
     color: #a7a7c1;
     font-size: 14px;
}
 #contacts-form input::-moz-placeholder {
     color: #a7a7c1;
     font-size: 14px;
}
 #contacts-form input::placeholder {
     color: #a7a7c1;
     font-size: 14px;
}
 #contacts-form textarea::-moz-placeholder {
     color: #a7a7c1;
     font-size: 14px;
}
 #contacts-form textarea::placeholder {
     color: #a7a7c1;
     font-size: 14px;
}
 .contact-icon {
     position: relative;
}
 .contact-icon::after {
     position: absolute;
     content: "";
     font-family: "Font Awesome 5 Free";
     font-weight: 700;
     display: inline-block;
     font-size: 16px;
     text-align: center;
     right: 30px;
     color: #e12454;
     top: 18px;
}
 .conatct-post-form .contact-icon::before{
     display: none;
}
 .contacts-message::after {
     content: "";
}
 .contacts-name::after {
     content: "";
}
 .contacts-email::after {
     content: "";
}
 .contacts-website::after {
     content: "";
}
 .navigation-border {
     border-top: 2px solid #f7f7f7;
     overflow: hidden;
}
 .navigation-border {
     border-top: 2px solid #f7f7f7;
     overflow: hidden;
}
 .bakix-navigation span {
     font-size: 14px;
     text-transform: uppercase;
     letter-spacing: 2px;
     font-weight: 500;
     display: block;
     margin-bottom: 10px;
}
 .bakix-navigation span a:hover {
     color: #e12454;
}
 .bakix-navigation h4 {
     font-size: 24px;
     margin: 0;
}
 .bakix-navigation h4 a:hover {
     color: #e12454;
}
 .basic-pagination ul {
     display: block;
}
 .basic-pagination ul li {
     display: inline-block;
     margin: 0 5px;
}
 @media (max-width: 767px) {
     .basic-pagination ul li {
         margin-bottom: 10px;
    }
}
 .basic-pagination ul li a {
     height: 70px;
     width: 70px;
     background: #ffffff;
     color: #9990b8;
     font-size: 16px;
     font-weight: 500;
     border-radius: 50%;
     line-height: 70px;
     margin: 0px;
     display: inline-block;
     text-align: center;
}
 .basic-pagination ul li a:hover ,.basic-pagination ul li.active a {
     background: #e12454;
     color: #ffffff;
     box-shadow: 0px 8px 16px 0px rgba(254, 69, 54, 0.3);
}
 .basic-pagination-2 ul li a {
     background: transparent;
     color: #9990b8;
     border: 2px solid #eaebec;
     height: 60px;
     width: 60px;
     line-height: 56px;
}
 .basic-pagination-2 ul li a:hover,.basic-pagination-2 ul li.active a {
     background: #e12454;
     color: #ffffff;
     border-color: #e12454;
     box-shadow: 0px 8px 16px 0px rgba(254, 69, 54, 0.3);
}
.postbox__gallery .slick-next:before,.postbox__gallery .slick-prev:before {
    display: none !important;
}
.home_blog_btn{
    text-align: right;
}
// masonry
.my-masonry-grid {
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}
