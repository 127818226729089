
  
.goog-te-gadget{
  color: transparent !important;
  font-size: 0px;
  /* margin-right: 25px;
  margin-top: -48px; */
}
#google_translate_element a {
  display: none;
}