.containersign {
  display: flex;
  width: 80%;
  background: #f1f1f1;
  margin: 100px auto 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.flex-column {
  display: flex;
  font-size: larger;
  width: 100%;
}

.flex-sm-row {
  flex-direction: row;
}

@media (max-width: 768px) {
  /* Adjust the breakpoint as per your needs */
  .flex-column {
    flex-direction: column;
  }
}

.tabs {
  padding: 15px;
  text-align: center;
  width: 100%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
  background: #e12454;
  border: medium none;
  border-radius: 0;
  color: #fff;
}