.container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: Roboto, sans-serif;
}

.review-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.review-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.review-item:last-child {
  margin-bottom: 0;
}

.review-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.patient-info {
  flex-grow: 1;
}

.patient-name {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.rating {
  margin: 5px 0;
  color: #666;
}

.comment {
  margin: 0;
  color: #555;
}
